/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine, RiParentLine, RiCalendarCheckLine, RiTimeLine } from "react-icons/ri"

const FamilyLeavePage = () => {
  return (
    <Layout>
      <SEO 
        title="Family & Parental Leave Policy Template | Comprehensive Guidelines"
        description="Download our customizable family and parental leave policy template covering maternity, paternity, adoption, and other caregiving leaves with legal compliance guidance."
        keywords={[
          "family leave policy template",
          "parental leave guidelines",
          "maternity leave policy",
          "paternity leave template",
          "adoption leave policy",
          "FMLA compliance",
          "caregiving leave guidelines"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Family & Parental Leave Policy Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive, customizable policy template to establish clear guidelines for 
              parental, family, and caregiving leave in your organization.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Template <RiFileDownloadLine />
              </Link>
              
              <button type="button" onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#parental-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Parental Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#family-medical-leave" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Family & Medical Leave
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#procedures" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Request Procedures
                  </Link>
                </li>
                <li sx={{ mb: 0 }}>
                  <Link to="#legal-compliance" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Legal Compliance
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiParentLine sx={{ color: 'primary' }} /> Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  A comprehensive family and parental leave policy is essential for supporting employees 
                  during important life events such as the birth or adoption of a child, or when caring 
                  for family members with serious health conditions. This policy template outlines various 
                  types of family-related leave available to employees, eligibility criteria, benefits 
                  continuation, and return-to-work provisions.
                </p>
                
                <p>
                  This template can be customized to align with your organization's specific values, size, 
                  industry, and applicable laws. It aims to help establish a supportive framework that 
                  promotes work-life balance while ensuring legal compliance.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Benefits of a Well-Structured Family Leave Policy:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Supports employees during significant life events</li>
                    <li>Improves employee retention and reduces turnover costs</li>
                    <li>Enhances employee morale, loyalty, and productivity</li>
                    <li>Demonstrates organizational commitment to work-life balance</li>
                    <li>Attracts top talent in competitive job markets</li>
                    <li>Ensures compliance with federal, state, and local laws</li>
                    <li>Establishes clear expectations and procedures for both employees and managers</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Parental Leave Section */}
            <div id="parental-leave" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiCalendarCheckLine sx={{ color: 'primary' }} /> Parental Leave
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] provides parental leave to eligible employees following the birth of an 
                  employee's child, the placement of a child with an employee in connection with adoption 
                  or foster care, or the care of a child during a serious health condition. This section 
                  outlines eligibility, duration, and provisions for different types of parental leave.
                </p>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Sample Parental Leave Provisions
                  </h4>
                  
                  <table sx={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    fontSize: '0.9rem'
                  }}>
                    <thead>
                      <tr>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Type of Leave
                        </th>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Duration
                        </th>
                        <th sx={{
                          textAlign: 'left',
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Pay Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Maternity Leave
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [X] weeks
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [Specify paid/unpaid status]
                        </td>
                      </tr>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Paternity Leave
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [X] weeks
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [Specify paid/unpaid status]
                        </td>
                      </tr>
                      <tr>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          Adoption/Foster Care
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [X] weeks
                        </td>
                        <td sx={{
                          p: 2,
                          borderBottom: '1px solid #dee2e6'
                        }}>
                          [Specify paid/unpaid status]
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Eligibility Requirements
                </h3>
                
                <p>
                  [Specify the eligibility requirements for parental leave, such as minimum length of 
                  service, full-time or part-time status, and any other applicable criteria.]
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Benefits Continuation
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      During approved parental leave, [Company Name] will maintain the employee's health 
                      benefits as if they continued to be actively employed. [Specify any cost-sharing 
                      arrangements or other details.]
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Return to Work
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Upon return from parental leave, employees will be reinstated to their original position 
                      or an equivalent position with equivalent pay, benefits, and other employment terms and 
                      conditions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Family & Medical Leave Section */}
            <div id="family-medical-leave" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiTimeLine sx={{ color: 'primary' }} /> Family & Medical Leave
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  In addition to parental leave, [Company Name] provides family and medical leave for eligible 
                  employees who need time off for qualifying reasons, such as caring for a family member with 
                  a serious health condition or the employee's own serious health condition. This section 
                  outlines the provisions for family and medical leave.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Qualifying Reasons
                </h3>
                
                <p>
                  Eligible employees may take family and medical leave for the following qualifying reasons:
                </p>
                
                <ul sx={{
                  pl: 4,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li>To care for a spouse, child, or parent with a serious health condition</li>
                  <li>Because of the employee's own serious health condition</li>
                  <li>For qualifying exigencies arising out of a family member's military service</li>
                  <li>To care for a family member who is a covered servicemember with a serious injury or illness</li>
                </ul>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Key FMLA Provisions
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li><strong>Duration:</strong> Up to 12 workweeks of leave in a 12-month period (or up to 26 workweeks for military caregiver leave)</li>
                    <li><strong>Eligibility:</strong> Employees who have worked for at least 12 months and at least 1,250 hours during the 12 months preceding the leave</li>
                    <li><strong>Job Protection:</strong> Upon return from FMLA leave, most employees must be restored to their original or equivalent positions</li>
                    <li><strong>Benefits Protection:</strong> Continuation of group health insurance coverage under the same terms and conditions</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Request Procedures Section */}
            <div id="procedures" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Procedures icon</title>
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1="16" y1="13" x2="8" y2="13" />
                  <line x1="16" y1="17" x2="8" y2="17" />
                  <polyline points="10 9 9 9 8 9" />
                </svg> 
                Request Procedures
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  To request family or parental leave, employees should follow the procedures outlined 
                  below. Proper documentation and advance notice (when possible) help ensure that leave 
                  requests are processed efficiently and that business continuity is maintained.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Notice Requirements
                </h3>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mb: 4
                }}>
                  <div sx={{
                    bg: '#f8f9fa',
                    p: 3,
                    borderRadius: '6px',
                    border: '1px solid #e9ecef'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Foreseeable Leave
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      When the need for leave is foreseeable (e.g., birth or adoption of a child, 
                      planned medical treatment), employees must provide at least 30 days' advance 
                      notice, or as much notice as is practicable under the circumstances.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    p: 3,
                    borderRadius: '6px',
                    border: '1px solid #e9ecef'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Unforeseeable Leave
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      For unforeseen leave (e.g., sudden illness, premature birth), employees should 
                      notify their supervisor and HR as soon as practicable, typically within 1-2 
                      business days of when the need for leave becomes known.
                    </p>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Application Process
                </h3>
                
                <ol sx={{
                  pl: 4,
                  '& li': {
                    mb: 3,
                    pl: 1
                  }
                }}>
                  <li>
                    <strong>Submit Request Form</strong>: Complete the Family/Parental Leave Request Form 
                    available from Human Resources or on the company intranet. Include the reason for leave, 
                    expected start date, and anticipated return date.
                  </li>
                  <li>
                    <strong>Provide Documentation</strong>: Submit appropriate documentation based on the type 
                    of leave requested:
                    <ul sx={{
                      mt: 2,
                      '& li': {
                        mb: 1
                      }
                    }}>
                      <li>Birth: Medical certification of the expected or actual birth date</li>
                      <li>Adoption/Foster Care: Documentation from the placement agency</li>
                      <li>Family Member's Health Condition: Medical certification from the family member's healthcare provider</li>
                      <li>Employee's Health Condition: Medical certification from the employee's healthcare provider</li>
                    </ul>
                  </li>
                  <li>
                    <strong>HR Review</strong>: Human Resources will review the request and documentation to 
                    determine eligibility and approve or deny the request within [X] business days.
                  </li>
                  <li>
                    <strong>Confirmation</strong>: Employees will receive written confirmation of the leave approval, 
                    including details about the leave period, pay status, benefits continuation, and return-to-work 
                    requirements.
                  </li>
                </ol>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                      aria-hidden="true"
                    >
                      <title>Info icon</title>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="17" x2="12.01" y2="17" />
                    </svg>
                    Communication During Leave
                  </h4>
                  
                  <p sx={{ mb: 0 }}>
                    While on leave, employees are expected to maintain reasonable communication with their 
                    supervisor or HR regarding their status and intent to return to work. If there are changes 
                    to the anticipated return date, employees should notify HR as soon as possible.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Legal Compliance Section */}
            <div id="legal-compliance" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Legal Compliance icon</title>
                  <path d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20z" />
                  <path d="M12 8v4l3 3" />
                </svg> 
                Legal Compliance
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] is committed to complying with all applicable federal, state, and local 
                  laws regarding family and medical leave. This section provides an overview of the laws 
                  that may affect our family and parental leave policies.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Federal Laws
                </h3>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mb: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Family and Medical Leave Act (FMLA)
                  </h4>
                  
                  <p>
                    The FMLA is a federal law that entitles eligible employees to take up to 12 weeks of 
                    unpaid, job-protected leave in a 12-month period for specified family and medical reasons. 
                    The FMLA applies to employers with 50 or more employees within a 75-mile radius.
                  </p>
                  
                  <p sx={{ mb: 0 }}>
                    Key provisions include:
                  </p>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    mt: 2,
                    '& li': {
                      mb: 1
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>Employee eligibility (12 months of employment, 1,250 hours worked)</li>
                    <li>Job and benefits protection during leave</li>
                    <li>Continuation of health insurance</li>
                    <li>Prohibition against retaliation for exercising FMLA rights</li>
                  </ul>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mb: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Pregnancy Discrimination Act (PDA)
                  </h4>
                  
                  <p sx={{ mb: 0 }}>
                    The PDA prohibits discrimination based on pregnancy, childbirth, or related medical conditions. 
                    Under the PDA, employers must treat pregnant employees the same as other employees who are similar 
                    in their ability or inability to work.
                  </p>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  State and Local Laws
                </h3>
                
                <p>
                  Many states and localities have enacted their own family and medical leave laws, which may 
                  provide greater benefits or cover more employees than federal law. [Company Name] complies 
                  with all applicable state and local laws regarding family and medical leave.
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Paid Family Leave Laws
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Several states have enacted paid family leave laws that provide wage replacement benefits 
                      to employees taking time off for qualifying family or medical reasons. [Company Name] will 
                      assist eligible employees in applying for these benefits where available.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Pregnancy Accommodation Laws
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Many states and localities require employers to provide reasonable accommodations to pregnant 
                      employees, which may include modified work schedules, temporary transfers, or light duty 
                      assignments. [Company Name] will work with pregnant employees to provide reasonable accommodations.
                    </p>
                  </div>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="40" 
                    height="40" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="1.5" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    sx={{ color: 'primary', mb: 3 }}
                    aria-hidden="true"
                  >
                    <title>Policy Statement icon</title>
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                  
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text',
                    textAlign: 'center'
                  }}>
                    Policy Statement
                  </h4>
                  
                  <p sx={{ 
                    mb: 0,
                    textAlign: 'center',
                    fontStyle: 'italic'
                  }}>
                    "[Company Name] is committed to providing family and parental leave benefits that comply with or 
                    exceed legal requirements, while supporting employees during important life events. We recognize 
                    the importance of work-life balance and strive to create a supportive and inclusive workplace for all employees."
                  </p>
                </div>
                
                <p sx={{ mt: 4 }}>
                  For specific questions about how federal, state, or local laws affect your leave rights, please 
                  contact Human Resources. Additionally, employees may wish to consult with legal counsel or government 
                  agencies for guidance on their rights under applicable laws.
                </p>
              </div>
            </div>
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Family & Parental Leave Policy Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to implement a comprehensive 
                family and parental leave policy for your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Leave request forms
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Documentation checklists
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> State law reference guide
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Manager implementation guide
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> FMLA compliance resources
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies/diversity-inclusion" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Previous: Diversity & Inclusion
              </Link>
              
              <Link to="/policies" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Back to All Policies <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FamilyLeavePage 